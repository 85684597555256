import styled, { css } from "styled-components";

export const $OfferApplicationModal = styled.div``;

export const $OfferApplicationTitle = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  background-image: linear-gradient(to right, #033024, #118738);
  div {
    background-color: transparent;
    .MuiStepLabel-label {
      color: #fff;
    }
    .MuiStepIcon-text {
      fill: #000;
    }
    .MuiStepIcon-root {
    }
    circle {
      /* fill:#fff; */
    }
  }
`;

export const $OfferApplicationBody = styled.div`
  .layout-container {
    height: 100%;
    display: flex;
    min-height: 40vh;
    justify-content: center;
    align-items: center;
  }
`;

export const $OfferApplicationInformations = styled.div`
  h4 {
    font-size: 20px;
  }
`;
