import React from "react";
import PropTypes from "prop-types";
import { $ContentbulletList } from "./SC_ContentBlockBulletedList.js";
import { ReactSVG } from "react-svg";
// import ListSvg from "../../../../SVG/arrow-list.svg";

const makeItBulletedList = (text, smallLength) => {
  text = text.replace(/;\s*$/, "");
  const elmts = text.split(";");
  if (elmts.length === 1) return text;

  const bulletedList = elmts.map((elmt, index) => {
    return (
      <li className={`${smallLength ? "smallLength" : "list"}`} key={index}>
        <ReactSVG
          className="icon-list-details"
          src={`../../../../SVG/Trace_731.svg`}
        ></ReactSVG>
        {elmt}
      </li>
    );
  });

  return <ul className="">{bulletedList}</ul>;
};

function ContentBlockBulletedList(props) {
  return (
    <$ContentbulletList>
      <div className="contentblock-container">
        {props.title ? (
          <h3
            className=""
            style={{
              fontSize: props.titleSize,
              fontWeight: props.titleFontWeight,
            }}
          >
            {props.title}
          </h3>
        ) : null}
        {props.description
          ? makeItBulletedList(props.description, props.smallLength)
          : null}
      </div>
    </$ContentbulletList>
  );
}

ContentBlockBulletedList.propTypes = {
  titleSize: PropTypes.string,
  titleFontWeight: PropTypes.string,
  smallTextLength: PropTypes.string,
};

ContentBlockBulletedList.defaultProps = {
  titleSize: "1.75rem",
  titleFontWeight: `bold`,
};

export default ContentBlockBulletedList;
