import styled from "styled-components";

export const $OfferDetail = styled.header`
  .offer-detail-container {
    padding: 0;
    /* div {
      margin-bottom: 0;
      background-color: #fff;
    } */

    .header-img {
      height: 30vw;
      width: 100%;
      position: relative;
      background: url(../../../../employeestas.webp) no-repeat center center;
      background-size: cover;
      @media only screen and (max-width: 768px) {
        height: 175px;
        background: url(../../../../employeestas-tablet.webp) no-repeat center
          center;
        background-size: cover;
      }

      @media only screen and (max-width: 425px) {
        height: 175px;
        background: url(../../../../employeestas-mobile.webp) no-repeat center
          center;
        background-size: cover;
      }

      .hexaLeft {
        position: absolute;
        width: 100%;
        height: 100%;
        svg {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 25%;
          height: auto;
        }
      }
      .hexaRight {
        position: absolute;
        width: 100%;
        height: 100%;
        svg {
          z-index: 1;
          position: absolute;
          right: 0;
          top: 0;
          width: 26%;
          transform: translateY(25%);
          height: auto;
        }
      }
    }
    .poste-name {
      background: #121f48;

      h1 {
        color: #fff;
        padding: 0.5em 10%;
        @media only screen and (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .city-container {
      text-transform: none;
      text-decoration: none;
      color: #ffffff;
      /* margin: 0.6em; */
      padding: 1em;
      font-size: 1.75rem;
      font-weight: bold;
      display: flex;
      background-color: #121f48;
      font-size: 26px !important;
      @media only screen and (min-width: 768px) {
        font-size: 30px !important;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 34px !important;
      }

      svg {
        margin-right: 1em;
      }
    }
    .share-container {
      text-align: center;
      padding: 2em;
      margin: 1em 0;
      position: relative;
      border: 5px solid #3b466a;
      /* margin: 1em; */
      background-size: cover;
      .title {
        font-size: 2rem;
        color: #3b466a;
        font-weight: bold;
        text-align: center;
      }
      .share-list {
        margin: 1em 0 0;
        display: flex;
        justify-content: center;
        button {
          margin: 0 0.3em;
        }
      }
    }
  }
  .footer-img-container img {
    width: 100%;
    max-height: 300px;
    padding: 15px 0;
  }

  .offer-detail-body {
    padding: 1em 2%;
    .rightSection {
      padding: 0 1.5em;
      @media only screen and (max-width: 1024px) {
        padding: 0%;
      }
    }
    @media only screen and (min-width: 1025px) {
      padding: 1em 10%;
    }
  }
  .postulationCTA {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    text-align: center;
    color: #121f48;
    font-weight: 600;
    font-size: 1.1em;
    background: url(../../../SVG/background-button-header.svg) no-repeat center
      center;
    background-size: contain;
    border: none;

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }

    svg {
      margin: 0 0 0 1em;
      /* transform: rotate(180deg); */
    }
  }
  .isMobile {
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
  .line {
    margin: 1em 0;
    border-bottom: 1px solid #eb5536;
    padding: 0.4em 0;
  }

  .apply_back_to_offers {
    display: flex;
    width: 100%;
    padding: 0;

    .soumettre-ma-candidature {
      width: 60%;
      padding: 1em 1em;
      background: #bad80a;
      align-items: center;
      display: flex;
      flex-direction: column;
      transition: 0.5s;

      .postulationCTA {
        width: 50%;
      }

      h3 {
        text-transform: uppercase;
        margin-bottom: 1em;
      }

      &:hover {
        width: 70%;
      }
    }

    .back-to-offers {
      cursor: pointer;
      padding-left: 4%;
      width: 40%;
      font-size: 1.4em;
      background: #28809b;
      color: #fff;
      display: flex;
      align-items: center;
      transition: 0.5s;

      .arrow-back-icon {
        float: left;
        height: auto;
        margin: 2px 15px 0 0;
        width: 1.7em;
        margin: 0;
      }

      div {
        line-height: 1;
        background: #28809b;
      }
      span {
        text-transform: uppercase;
        font-weight: 600;
      }

      &:hover {
        width: 50%;
      }
    }
  }
`;
