import React from "react";
import SEO from "../../seo";
import BaseLayout from "../../BaseLayout";
import ContentBlock from "../../dependentComponent/contentblock/ContentBlock";
import ContentBlockBulletedList from "../../dependentComponent/contentblockbulletedlist/ContentBlockBulletedList";
import { Container, Row, Col } from "react-bootstrap";
import Application from "../offerApplication/index";
import getUrlParameter from "../../dependentComponent/functions/getParamsValue";
import { $OfferDetail } from "./SC_OfferDetail";
import { ReactSVG } from "react-svg";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import CandidatureSpontanee from "../candidatureSpontanee/CandidatureSpontanee";
import { OfferApplication } from "../offerApplicationNew/Application";
// import { Passers } from "prop-passer";

const { useRef } = React;

const offerNotSuitableForTheContact_OfferId = 7720;

// function OfferFooterBlock(props) {
//   const handleEmailClick = (event) => {
//     event.preventDefault();
//     const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//     if (emailRegex.test(props.emailValue)) {
//       props.applicationRef.current.handleOfferApplicationVisibility(true);
//     } else {
//       alert("Veuillez entrer une adresse courriel valide");
//     }
//   };
//   const {
//     url = String(window.location),
//     title = "Steadylearner Website",
//     shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
//     size = "2.5rem",
//   } = this.props;

//   return (
//     <>
//       <Col sm={12} md={5} className="footer-img-container">
//         <Image src={props.offerFooterImgSrc} />
//       </Col>
//       <SimilarOffer
//         fechedOfferList={props.fechedOfferList}
//         actualOffer={props.actualOffer}
//       />
//       <Col sm={12} md={7}>
//         <TextBlock title="Ce poste ne vous convient pas?" />
//         <div className="courrier">
//           <Form onSubmit={handleEmailClick}>
//             <Form.Row className="align-items-center">
//               <Col sm={9}>
//                 <Form.Control
//                   className="mb-2"
//                   id="inlineFormInput"
//                   placeholder="Adresse courriel personnelle"
//                   value={props.emailValue}
//                   onChange={(e) => {
//                     props.setEmailValue(e.target.value.trim());
//                   }}
//                 />
//               </Col>
//               <Col sm={3}>
//                 <Button
//                   type="submit"
//                   className="mb-2"
//                   onClick={handleEmailClick}
//                 >
//                   Valider
//                 </Button>
//               </Col>
//             </Form.Row>
//           </Form>
//         </div>
//       </Col>
//     </>
//   );
// }

function OfferDetail({ pageContext: { offer, fechedOfferList } }) {
  const isBrowser = typeof window !== "undefined";

  // const videoLinkExist = true;
  const [emailValue, setEmailValue] = React.useState("");
  const childRef = useRef();

  if (!offer) return <></>;
  return (
    <BaseLayout destailPage={true}>
      <SEO
        description={`${offer.descriptionSeo}`}
        title={`${offer.titleSeo}`}
      />
      <$OfferDetail>
        <Container className="offer-detail-container" fluid>
          <Row>
            <Col sm={12} style={{ padding: "0" }}>
              <div className="header-img">
                <ReactSVG
                  className="hexaLeft"
                  src={`../../../../SVG/Trace_884.svg`}
                ></ReactSVG>
                <ReactSVG
                  className="hexaRight"
                  src={`../../../../SVG/Page-1.svg`}
                ></ReactSVG>
              </div>
            </Col>
            <Col sm={12} style={{ padding: "0" }}>
              <div className="poste-name">
                <h1>{`${offer.titreOffre}`}</h1>
              </div>
              {/* <TextBlock
                title={offer.titreOffre}
                text={" - " + offer.nombrePostes + " poste(s) disponible(s)"}
                /> */}
            </Col>
          </Row>
          <Row className="offer-detail-body">
            {/* <Col sm={1}></Col> */}
            <Col sm={12} md={7} lg={7} style={{ padding: "0" }}>
              {/* {videoLinkExist ? (
                <div
                  class="d-block d-md-none"
                  style={{ width: "auto", height: "auto", padding: "15px" }}
                >
                  <ResponsiveEmbed aspectRatio="16by9">
                    <embed src="https://www.youtube.com/embed/-jGL8wNnU1k" />
                  </ResponsiveEmbed>
                </div>
              ) : null} */}
              <div className="padding-15px apply-for-container isMobile">
                <span
                  className="postulationCTA"
                  size="lg"
                  onClick={() =>
                    childRef.current.handleOfferApplicationVisibility(true)
                  }
                  block
                >
                  Postuler pour cet emploi
                  <ReactSVG
                    className="icon-advantage"
                    src={`../../../../SVG/mouse-click.svg`}
                  ></ReactSVG>
                </span>
              </div>
              <ContentBlock
                title="Description du poste"
                description={offer.descriptionOffre}
              />
              <ContentBlockBulletedList
                title="Exemples de tâches à réaliser"
                description={offer.exempleTachesARealiser}
              />
              <ContentBlockBulletedList
                title="Compétences recherchées"
                description={offer.competencesRequises}
              />
              <ContentBlockBulletedList
                title="Formation requise"
                subtitle={
                  offer.equivalenceObligatoire === 1
                    ? "Les diplômes provenant de l'extérieur du Québec, doivent obligatoirement avoir obtenus un équivalence du Ministère."
                    : false
                }
                description={offer.formationRequise}
              />
              {/* <ContentBlockBulletedList
                title="On dit de vous que vous êtes...."
                description={offer.caracteristiquesPersonnelles}
                smallTextLength="smallLength"
              /> */}
              {/* <OfferApplication
                jobId={offer.jobId}
                email={emailValue}
                ref={childRef}
                formParams={getUrlParameter("form") ? true : false}
              /> */}
              <ContentBlockBulletedList
                title="Conditions d'emploi"
                description={offer.salaire + ";" + offer.autresConditions}
              />
              <div className="padding-15px apply-for-container d-none d-md-block">
                <span
                  className="postulationCTA"
                  size="lg"
                  onClick={() =>
                    childRef.current.handleOfferApplicationVisibility(true)
                  }
                  block
                >
                  Postuler pour cet emploi
                  <ReactSVG
                    className="icon-advantage"
                    src={`../../../../SVG/mouse-click.svg`}
                  ></ReactSVG>
                </span>
                <div className="line"></div>
              </div>
            </Col>
            <Col sm={12} md={5} lg={5} className="rightSection">
              {/* {videoLinkExist ? (
                <div
                  className="d-none d-md-block"
                  style={{ width: "auto", height: "auto", padding: "15px" }}
                >
                  <ResponsiveEmbed aspectRatio="16by9">
                    <embed src="https://www.youtube.com/embed/-jGL8wNnU1k" />
                  </ResponsiveEmbed>
                </div>
              ) : null} */}

              {/* <ContentBlock
                title="Notre Client"
                description={offer.nomEmployeur}
              /> */}
              <a
                href={`${[offer.adresseMapEmployeur]}`}
                target="_blank"
                className="city-container"
              >
                <ReactSVG
                  className="icon-map"
                  src={`../../../../SVG/icon-map-orange.svg`}
                ></ReactSVG>
                <span>{offer.villeEmployeur}</span>
              </a>
              <ContentBlockBulletedList
                title="Environnement de travail"
                description={offer.environnementTravailEmployeur}
              />

              {/* {offer.adresseMapEmployeur ? (
                <div style={{ width: "auto", height: "auto", padding: "15px" }}>
                  <ResponsiveEmbed aspectRatio="16by9">
                    <embed src={offer.adresseMapEmployeur} />
                  </ResponsiveEmbed>
                </div>
              ) : null} */}
              <span
                className="postulationCTA"
                size="lg"
                onClick={() =>
                  childRef.current.handleOfferApplicationVisibility(true)
                }
                block
              >
                Postuler pour cet emploi
                <ReactSVG
                  className="icon-advantage"
                  src={`../../../../SVG/mouse-click.svg`}
                ></ReactSVG>
              </span>
              {isBrowser && (
                <div className="share-container">
                  <span className="title">Partager l'offre</span>
                  <div className="share-list">
                    <FacebookShareButton
                      url={window.location.href}
                      quote="Voici une nouvelle opportunité d’emploi à découvrir chez STAS. Joins-toi à l’équipe !"
                    >
                      <FacebookIcon size={35} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={window.location.href}
                      title="Voici une nouvelle opportunité d’emploi à découvrir chez STAS. Joins-toi à l’équipe !"
                    >
                      <TwitterIcon size={35} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      source={window.location.href}
                      title="Voici une nouvelle opportunité d’emploi à découvrir chez STAS. Joins-toi à l’équipe !"
                    >
                      <LinkedinIcon size={35} round={true} />
                    </LinkedinShareButton>
                    <EmailShareButton
                      url={window.location.href}
                      subject={`Offres d'emploi ${offer.titreOffre}`}
                      body="Voici une nouvelle opportunité d’emploi à découvrir chez STAS. Joins-toi à l’équipe !"
                    >
                      <EmailIcon size={35} round={true} />
                    </EmailShareButton>
                  </div>
                </div>
              )}

              {/* <ContentBlock
                title="Personne Contact"
                contactViaLinkedin={offer.idUtilisateur.urlLinkedin}
              /> */}
              <br />
              {/* setting the correct jobId whether the job is suitable for the contact or not*/}
              <Application
                jobId={
                  emailValue !== ""
                    ? offerNotSuitableForTheContact_OfferId
                    : offer.tags
                }
                email={emailValue}
                ref={childRef}
                formParams={getUrlParameter("form") ? true : false}
              />
            </Col>
          </Row>
          <div
            style={{ width: "100%", height: "1em", backgroundColor: "#ffffff" }}
          />
          {/* <div className="padding-15px apply-for-container apply_back_to_offers">
            <div className="soumettre-ma-candidature">
              <h3>Soumettre ma candidature</h3>
              <span
                className="postulationCTA"
                onClick={() =>
                  childRef.current.handleOfferApplicationVisibility(true)
                }
                block
              >
                Postuler sur cet emploi
              </span>
            </div>

            <div className="back-to-offers">
              <div style={{ display: "flex" }}>
                <ArrowBackIosIcon className="arrow-back-icon" />
                <span>
                  Retourner <span style={{ display: "block" }}>aux offres</span>
                </span>
              </div>
            </div>
          </div>
          <Row>
            <OfferFooterBlock
              emailValue={emailValue}
              offerFooterImgSrc={offerFooterImgSrc}
              setEmailValue={setEmailValue}
              applicationRef={childRef}
              fechedOfferList={fechedOfferList}
              actualOffer={offer}
            />
          </Row> */}
        </Container>
      </$OfferDetail>

      <CandidatureSpontanee childRef={childRef} />
    </BaseLayout>
  );
}

export default OfferDetail;
